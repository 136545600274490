import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cogoToast from "cogo-toast";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";

import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVert from "@mui/icons-material/MoreVert";
import { convertDateInStandardFormat } from "../../../utils/formatting/date";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import { Tooltip } from "@material-ui/core";
import { getCall, postCall } from "../../../Api/axios";

const Settlementdetails = () => {
  const [order, setOrder] = useState();
  const [counterpartyReconStatus, setCounterpartyReconStatus] = useState("");
  const [orderReconStatus, setOrderReconStatus] = useState("");
  const [receiverOrderInfo, setReceiverOrderInfo] = useState();
  const [differenceAmount, setDifferenceAmount] = useState("");
  const [message, setMessage] = useState("");
  const [settlementInfo, setSettlementInfo] = useState();
  const [receiverOrder, setReceiverOrder] = useState();
  const cardClass = `border-2 border-gray-200 rounded-lg p-2 bg-slate-50`;
  const [openDialog, setOpenDialog] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const handleOpenDialog = async () => {

    await calculateDiffAmount();
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const checkFormValidity = () => {
    const isValid =
      counterpartyReconStatus !== "" &&
      orderReconStatus !== "" &&
      differenceAmount !== "" &&
      message !== "";
    if(isValid){
      setIsFormValid(true);
    }
    else{
      setIsFormValid(false);
    }
    
  };
   
  
  const getSettlements = () => {
    const url = `/api/v1/settlements/${params.receiverReconRequestId}/${params.order_id}/${params.id}`;
    getCall(url).then((res) => {    
    setOrder(res); 
    const settlementDetails = res?.ReceiverReconSettlementDetails || [];
    console.log("Here is the settlementDetails",res?.ReceiverReconSettlementDetails);
    setSettlementInfo(settlementDetails);
    console.log("Here is the settlementInfo",res?.ReceiverReconOrdersInfo);
    setReceiverOrderInfo(res?.ReceiverReconOrdersInfo || []);

      
    
    setLoading(false);
  }, (error)=>{ cogoToast.error("Error fetching settlements:", error);});
  };
  const calculateDiffAmount = () => {
    return new Promise((resolve, reject) => {
       let totalSettlementAmount = 0;
       console.log("Here is the settlementInfo array", settlementInfo);
       console.log("Type of settlementInfo", typeof(settlementInfo));
       settlementInfo.forEach((settlement) => {
         const settlementAmount = parseFloat(settlement.settlement_amount);
         if (!isNaN(settlementAmount)) {
           totalSettlementAmount += settlementAmount;
         }
       });
       console.log("Total Settlement Amount:", totalSettlementAmount);
       let totalSellingPrice = parseFloat(order?.orderDetails.quote.price.value || order?.orderDetails.payment.params.amount);
       let withHoldingAmount = parseFloat(order?.ReceiverReconOrdersInfo[0].payment_withholding_amount) || 0;
       let deductionByCollector = parseFloat(order?.ReceiverReconOrdersInfo[0].deduction_by_collector_value) || 0;
       console.log("totalSellingPrice", totalSellingPrice);
       console.log("withHoldingAmount", withHoldingAmount);
       console.log("deductionByCollector", deductionByCollector);
       let differenceAmount = totalSellingPrice - (totalSettlementAmount + withHoldingAmount + deductionByCollector);
       setDifferenceAmount(Math.abs(differenceAmount));
       if (differenceAmount < 0) {
         setCounterpartyReconStatus("02");
         setMessage("more");
       } else if (differenceAmount === 0) {
         setCounterpartyReconStatus("01");
         setMessage("null");
       } else {
         setCounterpartyReconStatus("03");
         setMessage("less");
       }
       resolve(); 
    });
   };
   
  useEffect(() => {
    setLoading(true);
    console.log("Here is the loading state ",loading)
    if (params.receiverReconRequestId && params.order_id) {
      getSettlements();

    } else {
      navigate("/settlements");
    }
  }, [navigate, params]);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const handleChangeCounterpartyReconStatus = (event) => {
    setCounterpartyReconStatus(event.target.value);

  };

  const handleChangeOrderReconStatus = (event) => {
    setOrderReconStatus(event.target.value);

  };

  const handleChangeDifferenceAmount = (event) => {
    setDifferenceAmount(event.target.value);

  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);

  };
  useEffect(() => {

    checkFormValidity();
  }, [counterpartyReconStatus, orderReconStatus, differenceAmount, message]);

  const handleVerify = () => {
    setLoading(true);

    
    console.log("Here is the loading state",loading);
    if(!isFormValid){
      setLoading(false);
      cogoToast.error("Please fill all the fields");
      
      return;
    }
    if(order && order.orderDetails){
      console.log("Here is the order",order);
      console.log(order.ReceiverReconOrdersInfo);
      console.log("Here ----3",order.ReceiverReconOrdersInfo[0].receiverReconRequestId);
      const data = {
        counterparty_recon_status: counterpartyReconStatus,
        order_recon_status: orderReconStatus,
        difference_amount: differenceAmount,
        message: message,
        recon_request_id: order?.ReceiverReconOrdersInfo[0].receiverReconRequestId,
        order_id: order?.orderDetails?.orderId,
        settlement_id: settlementInfo[0].id,
      };
      console.log("Here is the data ", data);
      const url = "/api/v1/verifySettlement";
      postCall(url, data).then((res) => {
        console.log("Here is the response from the API");
        console.dir(res, { depth: null });
        
        handleCloseDialog();
        
        setTimeout(() => {
          setLoading(false);
          cogoToast.success("Settlement Verified Successfully");
          navigate("/settlements");
        }, 5000);
       
      });
      
    }
    else{
      setLoading(false);
      cogoToast.error("Some Error Occured....");
    }
  };
  useEffect(() => {
    if (
      order &&
      order.orderDetails &&
      order.orderDetails.quote &&
      order.orderDetails.quote.breakup
    ) {
      // Loop through each item in the "breakup" array
      order.orderDetails.quote.breakup.forEach((item) => {
        // Check if the item is for delivery charges
        if (item.title === "Delivery charges") {
          // Extract the price value for delivery charges
          setDeliveryCharges(parseFloat(item.price.value));
        }
      });
    }
  }, [order]);
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mx-auto my-8">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#fff' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
      <div>
        <BackNavigationButton onClick={handleGoBack} />
        <Button autoFocus onClick={handleOpenDialog}>
          Verify Settlement
        </Button>
      </div>

      <div className="flex flex-col">
        {order && order.orderDetails && (
          <div className={`${cardClass} my-4 p-4`}>
            <div className="flex justify-between">
              <p className="text-lg font-semibold mb-2">Order Summary</p>
            </div>
            <Divider orientation="horizontal" />
            <Divider orientation="horizontal" />
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">Order ID</p>
              <p className="text-base font-semibold font-normal">
                {order?.orderDetails?.orderId}
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">Created On</p>
              <p className="text-base font-normal">
                {convertDateInStandardFormat(order?.orderDetails?.createdAt)}
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">Updated At</p>
              <p className="text-base font-normal">
                {convertDateInStandardFormat(order?.orderDetails?.updatedAt)}
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">Order Status</p>
              <p className="text-base font-normal">
                {order?.orderDetails?.state}
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">Payment Method</p>
              <p className="text-base font-normal">
                {order?.orderDetails?.payment?.type}
              </p>
            </div>
            <div className="flex justify-between mt-3 mb-3">
              <p className="text-base font-normal">Buyer name</p>
              <p className="text-md font-normal">
                {order?.orderDetails?.billing?.name}
              </p>
            </div>
            <Divider orientation="horizontal" />
            <Divider orientation="horizontal" />
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">Total Price</p>
              <p className="text-base font-normal">
                {order?.orderDetails?.quote?.price?.value}
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">Buyer App Finder Fee Type</p>
              <p className="text-base font-normal">
                {
                  order?.orderDetails?.payment[
                    "@ondc/org/buyer_app_finder_fee_type"
                  ]
                }
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">
                Buyer App Finder Fee Amount
              </p>
              <p className="text-base font-normal">
                {parseFloat(
                  order?.orderDetails?.payment[
                    "@ondc/org/buyer_app_finder_fee_amount"
                  ]
                )}
              </p>
            </div>
            <div className="flex justify-between mt-3">
              <p className="text-base font-normal">Total Delivery Fee</p>
              <p className="text-base font-normal">
                {parseFloat(deliveryCharges)}
              </p>
            </div>
          </div>
        )}
        <div className={`${cardClass} my-4 p-4`}>
          <div className="flex justify-between">
            <p className="text-lg font-semibold mb-2">Settlement Details</p>
          </div>
          <Divider orientation="horizontal" />
          <Divider orientation="horizontal" />
          {settlementInfo &&
            settlementInfo.map((settlement, index) => (
              <div key={index}>
                <p className="text-base font-semibold mt-2">
                  Settlement #{index + 1}
                </p>
                <Divider orientation="horizontal" />
                <Divider orientation="horizontal" />
                {settlement.settlement_type && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">Settlement Type</p>
                    <p className="text-base font-normal">
                      {settlement.settlement_type}
                    </p>
                  </div>
                )}

                {settlement.settlement_amount && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">Settlement Amount</p>
                    <p className="text-base font-normal">
                      Rs. {settlement.settlement_amount}
                    </p>
                  </div>
                )}

                {settlement.settlement_counterparty && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement CounterParty
                    </p>
                    <p className="text-base font-normal">
                      {settlement.settlement_counterparty}
                    </p>
                  </div>
                )}

                {settlement.settlement_phase && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">Settlement Phase</p>
                    <p className="text-base font-normal">
                      {settlement.settlement_phase}
                    </p>
                  </div>
                )}
                {settlement.bank_name && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement Bank Name
                    </p>
                    <p className="text-base font-normal">
                      {settlement.bank_name}
                    </p>
                  </div>
                )}
                {settlement.settlement_bank_account_no && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement Bank Account No
                    </p>
                    <p className="text-base font-normal">
                      {settlement.settlement_bank_account_no}
                    </p>
                  </div>
                )}
                {settlement.settlement_ifsc_code && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement IFSC Code
                    </p>
                    <p className="text-base font-normal">
                      {settlement.settlement_ifsc_code}
                    </p>
                  </div>
                )}
                {settlement.branch_name && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement Branch Name
                    </p>
                    <p className="text-base font-normal">
                      {settlement.branch_name}
                    </p>
                  </div>
                )}
                {settlement.upi_address && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement UPI Address
                    </p>
                    <p className="text-base font-normal">
                      {settlement.upi_address}
                    </p>
                  </div>
                )}

                {settlement.beneficiary_address && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement Beneficiary Address
                    </p>
                    <p className="text-base font-normal">
                      {settlement.beneficiary_address}
                    </p>
                  </div>
                )}
                {settlement.beneficiary_address && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement Beneficiary Address
                    </p>
                    <p className="text-base font-normal">
                      {settlement.beneficiary_address}
                    </p>
                  </div>
                )}

                {settlement.beneficiary_name && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement Beneficiary Name
                    </p>
                    <p className="text-base font-normal">
                      {settlement.beneficiary_name}
                    </p>
                  </div>
                )}
                {settlement.settlement_status && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">Settlement Status</p>
                    <p className="text-base font-normal">
                      {settlement.settlement_status}
                    </p>
                  </div>
                )}
                {settlement.settlement_reference && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">
                      Settlement Reference
                    </p>
                    <p className="text-base font-normal">
                      {settlement.settlement_reference}
                    </p>
                  </div>
                )}
                {settlement.settlement_timestamp && (
                  <div className="flex justify-between mt-3">
                    <p className="text-base font-normal">Settlement Time</p>
                    <p className="text-base font-normal">
                      {convertDateInStandardFormat(
                        settlement.settlement_timestamp
                      )}
                    </p>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      </>
      )};
      
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Verify Settlement</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to verify this settlement?
          </Typography>
          <FormControl fullWidth sx={{ marginBottom: '20px' }}>
            <InputLabel>Counterparty Recon Status</InputLabel>
            <Select
              value={counterpartyReconStatus}
              onChange={handleChangeCounterpartyReconStatus}
            >
              <MenuItem value="01">Paid</MenuItem>
              <MenuItem value="02">Overpaid</MenuItem>
              <MenuItem value="03">Underpaid</MenuItem>
              <MenuItem value="04">Not Paid</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginBottom: '20px' }}>
            <InputLabel>Order Recon Status</InputLabel>
            <Select
              value={orderReconStatus}
              onChange={handleChangeOrderReconStatus}
            >
              <MenuItem value="01">Provisional</MenuItem>
              <MenuItem value="02">Finale</MenuItem>
              <MenuItem value="03">Deemed Settled</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginBottom: '20px' }}>
            <TextField
              label="Difference Amount"
              variant="outlined"
              value={differenceAmount}
              onChange={handleChangeDifferenceAmount}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth sx={{ marginBottom: '20px' }}>
            <InputLabel>Message Code</InputLabel>
            <Select value={message} onChange={handleChangeMessage}>
              <MenuItem value="less">Lesser Amount</MenuItem>
              <MenuItem value="more">More Amount</MenuItem>
              <MenuItem value="null">Null</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={loading}>Close</Button>
          <Button autoFocus onClick={handleVerify} disabled={loading} >
            {loading ? <CircularProgress size={20} /> : 'Verify'};
            
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  );
};
export default Settlementdetails;
