import React, { useState } from "react";
import cogoToast from "cogo-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import RenderInput from "../../../utils/RenderInput";
import GemstoneField from "./gemstonefields";
import { gemStone } from "./product-fields-modified";
import {
  PRODUCT_SUBCATEGORY,
  FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY,
} from "../../../utils/constants";
import { allProductFieldDetails } from "./product-fields";

const AddProductInfo = ({
  allFields,
  fields,
  state,
  form,
  setFocusedField,
  
 
}) => {
  console.log("AddProductInfo fields : ", allFields, fields, state, form, setFocusedField);
  const { formValues, setFormValues, errors, setErrors } = form;
  const [showDialog, setShowDialog] = useState(false);
  const [newGemstone, setNewGemstone] = useState({
    gemstoneType: "",
    gemstoneSize: "",
    gemstoneCount: "",
    gemstoneCostPerStone: "",
  });

  const getProductFieldDetails = (category_id) => {
    return allFields.find((field) => field.id === category_id);
  };
  const handleGemstoneChange = (updatedGemstone) => {
    // Update the local state for the current gemstone
    setNewGemstone((prevGemstone) => ({
      ...prevGemstone,
      ...updatedGemstone,
    }));
  };

  const handleDialogSubmit = () => {
    // Add the gemstone to the form state only if it has valid values
    if (
      newGemstone.gemstoneType &&
      newGemstone.gemstoneCount &&
      newGemstone.gemstoneCostPerStone
    ) {
      // Update the gemstone values in the gemstone array in the parent component
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        gemstones: [...prevFormValues.gemstones, newGemstone],
      }));

      setNewGemstone({
        gemstoneType: "",
        gemstoneSize: "",
        gemstoneCount: "",
        gemstoneCostPerStone: "",
      });

      setShowDialog(false);
    } else {
      cogoToast.error("Please fill out all the required fields");
    }
  };

  const handleDialogCancel = () => {
    setShowDialog(false);
  };
  const handleGemstoneDelete = (index) => {
    const updatedGemstones = [...formValues.gemstones];
    updatedGemstones.splice(index, 1);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      gemstones: updatedGemstones,
    }));
  };
  let priceHeadingBool = 1;

  return (
    <>
      

      {fields?.map((category_id, index) => {
        let item = getProductFieldDetails(category_id);
        
        let returnElement = true;

        if (formValues?.productSubcategory1) {
          const subCatList = PRODUCT_SUBCATEGORY[formValues?.productCategory];
          const selectedSubCatObject = subCatList?.find(
            (subitem) => subitem.value === formValues?.productSubcategory1
          );
          if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
            const hiddenFields =
              FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
                selectedSubCatObject.protocolKey
              ];
            const fielditemAvailableInHidden = hiddenFields.find(
              (hiddenItem) => hiddenItem === item.id
            );
            if (fielditemAvailableInHidden) {
              returnElement = false;
            }
          }
        } else {
        }

        if (returnElement) {
          
          if (item.id === "images") {
            return (<>
              <label className="text-2xl font-semibold mb-4 mt-14">Basic Product Details:</label>
              <RenderInput
                key={item.id}
                previewOnly={
                  state?.productId && item.id === "productCode" ? true : false
                }
                item={{
                  ...item,
                  error: errors?.[item.id] ? true : false,
                  helperText: errors?.[item.id] || "",
                }}
                state={formValues}
                stateHandler={setFormValues}
                setFocusedField={setFocusedField}
              />
            </>
            )
          } 
          
          if (item.id === "diamonds_weight") {
            return (<>
              <label className="text-2xl font-semibold mb-4 mt-14">Diamond Details:</label>
              <RenderInput
                key={item.id}
                previewOnly={
                  state?.productId && item.id === "productCode" ? true : false
                }
                item={{
                  ...item,
                  error: errors?.[item.id] ? true : false,
                  helperText: errors?.[item.id] || "",
                }}
                state={formValues}
                stateHandler={setFormValues}
                setFocusedField={setFocusedField}
              />
            </>
            )
          }
          
          if (priceHeadingBool && (item.id === "wastageOnGold" || item.id === "diamondCostPerCt")){
            priceHeadingBool = 0;
            return (<>
              <label className="text-2xl font-semibold mb-4 mt-14">Pricing:</label>
              <RenderInput
                key={item.id}
                previewOnly={
                  state?.productId && item.id === "productCode" ? true : false
                }
                item={{
                  ...item,
                  error: errors?.[item.id] ? true : false,
                  helperText: errors?.[item.id] || "",
                }}
                state={formValues}
                stateHandler={setFormValues}
                setFocusedField={setFocusedField}
              />
            </>
            )
          }

          return (
            <RenderInput
              key={item.id}
              previewOnly={
                state?.productId && item.id === "productCode" ? true : false
              }
              item={{
                ...item,
                error: errors?.[item.id] ? true : false,
                helperText: errors?.[item.id] || "",
              }}
              state={formValues}
              stateHandler={setFormValues}
              setFocusedField={setFocusedField}
            
            />
          );
        
        } else {
          return <></>;
        }
      })}
      <Button onClick={() => setShowDialog(true)} color="primary">
        Add Gemstone
      </Button>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <GemstoneField
            gemstone={newGemstone}
            onChange={handleGemstoneChange}
            gemstoneFields={gemStone}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <h2>Added Gemstones:</h2>
        <ul>
          {formValues?.gemstones?.map((gemstone, index) => (
            <li key={index}>
              {gemstone &&
                `Type: ${gemstone.gemstoneType}, Size: ${gemstone.gemstoneSize}, Count: ${gemstone.gemstoneCount}, Cost per Stone: ${gemstone.gemstoneCostPerStone}`}
              <IconButton onClick={() => handleGemstoneDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default AddProductInfo;
