import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,

} from "@mui/material";
import cogoToast from "cogo-toast";
import { styled } from "@mui/material/styles";
import { convertDateInStandardFormat } from "../../../utils/formatting/date.js";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)({
  "&.MuiTableCell-root": {
    fontWeight: "bold",
  },
});

export default function SettlementTable(props) {
  const {
    page,
    rowsPerPage,
    totalRecords,
    handlePageChange,
    handleRowsPerPageChange,
    columns,
    data,
    orderDetails,
  } = props;
 
  const navigate = useNavigate();
  const onPageChange = (event, newPage) => {
    handlePageChange(newPage);
  };

  const onRowsPerPageChange = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10));
    handlePageChange(0);
  };

  const renderColumn = (row, column) => {
    switch (column.id) {
      case "receiverReconRequestId":
        return <span>{row.receiverReconRequestId}</span>;
      case "order_id":
        return <span>{row.order_id}</span>;
      case "createdAt":
        return <span>{convertDateInStandardFormat(row.createdAt)}</span>;
      case "collector_app_id":
        return <span>{row.collector_app_id}</span>;
      case "provider_name":
        return <span>{row.provider_name}</span>;
      case "payment_status":
        return <span>{row.payment_status}</span>;
      case "payment_params_amount":
        return <span>{row.payment_params_amount}</span>;
      case "seller_approval":
        return <span>{row.seller_approval}</span>;
      case "total_price":
        const matchingOrder = orderDetails.find(
          (order) => order.orderId === row.order_id
        );

        if (matchingOrder) {
          return <span>{matchingOrder.total_price}</span>;
        } else {
          return <span>N/A</span>;
        }
      default:
        return null;
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#1976d2",
                    color: "#fff",
                  }}
                  className="font-medium"
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} hover role="checkbox" tabIndex={-1} className={row.seller_approval === "Completed" ? "completed-row" : ""}>
                {columns.map((column, idx) => (
                  <TableCell
                    key={idx}
                    align={column.align}
                    onClick={() => {
                      if (row.seller_approval !== "Completed") {
                        navigate(
                          `/Settlementdetails/${row.receiverReconRequestId}/${row.order_id}/${row.id}`
                        );
                      } else {
                       
                        cogoToast.success("This Settlement is already verified....");
                      }
                    }}
                  >
                    {renderColumn(row, column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  );
}
