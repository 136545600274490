import { useState, useEffect } from "react";
import Navbar from "../../Shared/Navbar";
import SettlementTable from "../Settlements/SettlementOrderTable";
import { useNavigate } from "react-router-dom";
import useCancellablePromise from "../../../Api/cancelRequest";
import { getCall } from "../../../Api/axios";
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from "@mui/material";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import { useParams } from "react-router-dom";
const columns = [
{id:"receiverReconRequestId",label:"Receiver Recon Request Id",minWidth:120,align:"center"},
  { id: "order_id", label: "Order Id", minWidth: 120, align: "center" },
  {
    id: "seller_approval",
    label: "Approval Status",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 100,
    align: "center",
  },
  {
    id: "createdAt",
    label: "Created On",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
    align: "center",
  },
  {
    id: "collector_app_id",
    label: "Collector App Name",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
    align: "center",
  },

  {
    id: "provider_name",
    label: "Provider Store Name",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 130,
    align: "center",
  },
  {
    id: "payment_status",
    label: "Payment Status",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 170,
    align: "center",
  },
  {
    id: "payment_params_amount",
    label: "Settled Amount",
    minWidth: 120,
    align: "center",

  },
  
 

];

export default function Settlements() {
  const theme = useTheme();
  const { cancellablePromise } = useCancellablePromise();
  const [orders, setOrders] = useState([]);
  const [orderDetails, setorderDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [user, setUser] = useState();
  const [columnList, setColumnList] = useState(columns);
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();

  
//   const getUser = async (id) => {
//     const url = `/api/v1/users/${id}`;
//     const res = await getCall(url);
//     setUser(res[0]);
//     return res[0];
//   };

//   useEffect(() => {
//     const user_id = localStorage.getItem("user_id");
//     getUser(user_id);
//   }, []);
const { id } = useParams();
  const getSettlements = () => {
    setLoading(true);
    const url = `/api/v1/settlementOrderInfo/${id}`;
    getCall(url)
      .then((resp) => {
        console.log("Here is the response from the API");
        console.dir(resp,{depth:null});
        console.log("Here is the ReceiverReconOrdersInfo data from the API");
        console.dir(resp.ReceiverReconOrdersInfo,{depth:null});
        console.log("Here is the ReceiverReconOrdersInfo.data data.data from the API");
        console.dir(resp.ReceiverReconOrdersInfo.data,{depth:null});
        
        setOrders(resp.ReceiverReconOrdersInfo.data);
        setorderDetails(resp.orderDetails.data);
      
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
      })
  };

  useEffect(() => {
    setLoading(true);
    getSettlements();
  }, [page, rowsPerPage]);

//   useEffect(() => {
//     if(user && user?.role?.name === "Organization Admin"){
//       const data = columns.filter((item) => item.id !== "provider_name")
//       setColumnList(data);
//     }
//   }, [user]);

  return (
    <div className="container mx-auto my-8">
    {loading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#fff' }}>
        <CircularProgress />
      </div>
    ) : (
      <>
        <div className="mb-4 flex flex-row justify-between items-center">
          <label style={{color: theme.palette.primary.main}} className="font-semibold text-2xl">Pending Settlements</label>
        </div>
        <SettlementTable
          columns={columnList}
          data={orders}
          orderDetails={orderDetails}
          totalRecords={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={(val) => setPage(val)}
          handleRowsPerPageChange={(val) => setRowsPerPage(val)}
        />
      </>
    )}
  </div>
  );
}