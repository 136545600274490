import { useTheme } from "@mui/material/styles";

// 
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import { getCall, postCall } from "../../../Api/axios";
import RenderInput from "../../../utils/RenderInput";
import useForm from "../../../hooks/useForm";
import { getFormErrors } from "../Product/utils";


const AddGoldRates = () => {
	const theme = useTheme();

	return (
		<div className="container mx-auto my-8">
			<div className="mb-4 flex flex-row justify-between items-center">
				<label
					style={{ color: theme.palette.primary.main }}
					className="font-semibold text-2xl"
				>
					Add Gold Rates
				</label>
      </div>
      <div>
        <AddGoldRateFormBox/>
      </div>
		</div>
	);
};

export default AddGoldRates;

const goldFields = [
  {
    id: "cityName",
    title: "City Name",
    placeholder: "Enter City Name",
    type: "input",
    required: true,
  },
  {
    id: "goldPrice",
    title: "Gold Price (without GST)",
    placeholder: "Enter Gold Price for below Purity",
    type: "number",
    required: true,
  },
  {
    id: "purity",
    title: "Gold Purity",
    placeholder: "Select Purity Here",
    type: "select",
    options: [
      { key: "24 KT", value: "24" },
      { key: "22 KT", value: "22" },  // default
      // { key: "18 KT", value: "18" },
    ],
    required: true,
    disableClearable: true,
  },
  {
    id: "entryDate",
    title: "Date of Entry",
    placeholder: "Enter Date",
    type: "date-picker",
    required: true,
    // isDisabled: true,
  },
];

const getTodaysDate = () => {
	const today = new Date();

	const day = String(today.getDate()).padStart(2, "0");
	const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
	const year = today.getFullYear();

	// Format the date as dd-mm-yyyy
	const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}



const AddGoldRateFormBox = () => {
  const navigate = useNavigate();
  const initialValues = {
    cityName: "",
    goldPrice: "",
    purity: "22",
    entryDate: getTodaysDate(),
  };
  const { formValues, setFormValues, errors, setErrors } = useForm({ ...initialValues })
  const [formSubmitted, setFormSubmited] = useState(false)


  const [User, setUser] = useState({});
  
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id);
  }, []);
  
  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    console.log("x4 User : ", res);
    setUser(res[0]);
    return res[0];
  };


  const validate = () => {
    const formErrors = getFormErrors(goldFields, formValues)
    setErrors({
      ...formErrors
    })
    return !Object.values(formErrors).some(val => val !== '')
  }

  const sendGoldData = async () => {
    try {
      const url = `/api/v1/goldratesBySuperAdmins`;

      // formatting price data
      let formPrice = parseFloat(formValues.goldPrice);
      let formPurity =  parseInt(formValues.purity);
      let priceData = { "TWENTY_FOUR": 0, "TWENTY_TWO": 0 };

      const getManupulatedPrice = (requiredPurity) => parseFloat((formPrice / formPurity * requiredPurity).toFixed(2));

      switch (formPurity) {
        case 24:
          priceData.TWENTY_FOUR = parseFloat(formPrice.toFixed(2));
          priceData.TWENTY_TWO = getManupulatedPrice(22);
          break;
        case 22:
          priceData.TWENTY_FOUR = getManupulatedPrice(24);
          priceData.TWENTY_TWO = parseFloat(formPrice.toFixed(2));
          break;
        // case 18:
        //   priceData.TWENTY_FOUR = getManupulatedPrice(24);
        //   priceData.TWENTY_TWO = getManupulatedPrice(22);
        //   break;
        default:
          console.error("Invalid purity");
          break;
      }
      const convertDateFormat = (dateString) => {
        const [day, month, year] = dateString.split("/");
        const formattedDate = `${month}/${day}/${year}`;
        return formattedDate;
      }

      console.log("x1 Sending data : ",2000, {"date" : convertDateFormat(formValues.entryDate), "priceLocation":formValues.cityName.toLowerCase(), "superAdminPrice": priceData});


      const res = await postCall(url, {"date" : convertDateFormat(formValues.entryDate), "priceLocation":formValues.cityName.toLowerCase(), "superAdminPrice": priceData});
      if (res) {
        //   navigate("/application/user-listings");
        cogoToast.success(`Gold Rates Added for ${formValues.cityName}.`);
      }
      
    } catch (error) {
      console.log(error);
      cogoToast.error(error.response?.data?.error || error.message || "Error while adding gold rates.");
    }
  };

  const checkDisabled = () => {
    return formValues.cityName === '' || formValues.purity === '' || formValues.goldPrice === '' || formValues.entryDate === ''
  };

  const renderHeading = () => {
    return "Add Gold Rates";
  };

  const renderGoldFields = () => {
    return goldFields.map((item, key) => {
      return (
        <RenderInput
          key={key}
          item={{ ...item, error: errors?.[item.id] ? true : false, helperText: errors?.[item.id] || '' }}
          state={formValues}
          stateHandler={setFormValues}
        />
      )
    });
  };

  const handleBack = () => {
    navigate("/application/user-listings?view=admin");
  };

  const handleSubmit = () => {
    setFormSubmited(true)
    if (validate()) {
      console.log("x1 Submitting formvalues : ", formValues);
      sendGoldData();
    }
  }

  useEffect(() => {
    if (!formSubmitted) return
    validate()
  }, [formValues])

  return (
    <div className="mx-auto !p-5 h-screen min-vh-100 overflow-auto bg-[#f0f0f0]">
      <div className="h-full flex fex-row items-center justify-center">
        <div
          className="flex w-full md:w-2/4 bg-white px-4 py-4 rounded-md shadow-xl h-max"
          style={{ minHeight: "75%" }}
        >
          <div className="m-auto w-10/12 md:w-3/4 h-max">
            <form>
              <p className="text-2xl font-semibold mb-4 text-center">
                {renderHeading()}
              </p>
              <div>{renderGoldFields()}</div>
              <div className="flex mt-6">
                <Button
                  type="button"
                  size="small"
                  style={{ marginRight: 10 }}
                  variant="text"
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={checkDisabled()}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};