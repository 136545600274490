import { useEffect, useState } from "react";
import InventoryTableSeller from "../Inventory/InventoryTableSeller";
import Button from "../../Shared/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { getCall } from "../../../Api/axios";
import useCancellablePromise from "../../../Api/cancelRequest";
import { isObjEmpty } from "../../../utils/validations";
import { PRODUCT_CATEGORY } from "../../../utils/constants";
import { useTheme } from "@mui/material/styles";
import FilterComponent from "../../Shared/FilterComponent";

const filterFields = [
  {
    id: "name",
    title: "",
    placeholder: "Search by Product Name",
    type: "input",
    variant: "standard",
  },
  {
    id: "category",
    title: "",
    placeholder: "Please Select Product Category",
    options: Object.entries(PRODUCT_CATEGORY).map(([key, value]) => {
      return { key: value, value: key };
    }),
    type: "select",
    variant: "standard",
    disableClearable: true,
  },
  {
    id: "stock",
    title: "Out of Stock",
    placeholder: "Please Select Product Category",
    type: "switch",
    containerClasses: "flex items-center",
    styles: {
      marginLeft: 2,
    },
  },
  {
    id: "QCStatus",
    title: "",
    placeholder: "QC Status",
    options: [
      {key:"InProgress",value:"InProgress"},
      {key:"Approved",value:"Approved"},
      {key:"Rejected",value:"Rejected"},
    ],
    
    type: "select",
    variant: "standard",
    disableClearable: true,
  },
];

const columns = [
  {
    id: "productSubcategory2",
    label: "Product Type",
    minWidth: 120,
    // format: (value) => PRODUCT_CATEGORY[value] || value,
  },
  { id: "productName", label: "Product Name", minWidth: 100 },
  {id:"productCode",label:"Product Number",minWidth:100},
  {
    id: "createdAt",
    label: "Created At",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
 
  // {
  //   id: "isCancellable",
  //   label: "Cancellable",
  //   boolean: true,
  //   minWidth: 100,
  // },
  // {
  //   id: "isReturnable",
  //   label: "Returnable",
  //   boolean: true,
  //   minWidth: 100,
  // },
  {
    id: "QCStatus",
    label: "QC Status",
    minWidth: 100,
  },
  {
    id: "QCDesc",
    label: "QC Comments",
    minWidth: 150,
  },

  // {
  //   id: "published",
  //   label: "Published",
  // },
];

export default function InventorySeller() {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();
  const { cancellablePromise } = useCancellablePromise();
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState(null); 
  const [filters, setFilters] = useState({
    name: "",
    category: "",
    stock: false,
    QCStatus:"",
  });

  const getProducts = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const user = await getUser(user_id);

      let url;
      if (user.role.name === "Super Admin") {
        url = `/api/v1/AdminList?limit=${rowsPerPage}&offset=${page}`;
      } else {
        url = `/api/v1/products?limit=${rowsPerPage}&offset=${page}`;
      }
      const res = await cancellablePromise(getCall(url));
      setProducts(res.data);
      setTotalRecords(res.count);
    } catch (error) {
      // cogoToast.error("Something went wrong!");
    }
  };

  const getOrgDetails = async (org_id) => {
    const url = `/api/v1/organizations/${org_id}/storeDetails`;
    const res = await getCall(url);
    return res;
  };

  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    setUser(res[0]);
    return res[0];
    
  };

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id).then((u) => {
      // roles - Organization Admin, Super Admin
      console.log("User:", u);
      if (u.isSystemGeneratedPassword) navigate("/initial-steps");
      getOrgDetails(u.organization);
    });
  }, []);

  useEffect(() => {
    getProducts();
  }, [page, rowsPerPage]);

  const handleRefresh = (data) => {
    getProducts();
  };

  const onReset = () => {
    setFilters({ name: "", category: null, stock: false });
    getProducts();
  };

  const onFilter = async () => {
    const filterParams = [];
    if (filters.name.trim() !== "") {
      filterParams.push(`name=${encodeURIComponent(filters.name)}`);
    }

    if (filters.category != undefined && filters.category !== "") {
      filterParams.push(`category=${encodeURIComponent(filters.category)}`);
    }

    if (!filters.stock) {
      filterParams.push(`stock=inStock`);
    } else {
      filterParams.push(`stock=outOfStock`);
    }

    const queryString = filterParams.join("&");
    const url = `/api/v1/SellerProducts?${queryString}`;

    const res = await cancellablePromise(getCall(url));
    setProducts(res.data);
    console.log("Res Data",res.data);
    setTotalRecords(res.count);
  };

  return (
    <>
      <div className="container mx-auto my-8">
        <div className="mb-4 flex flex-row justify-between items-center">
          <label
            style={{ color: theme.palette.primary.main }}
            className="font-semibold text-2xl"
          >
             {user && user.role && user.role.name === "Super Admin" ? "QC Status" : "Inventory"}
          </label>
          <div className="flex">
            {user && user.role && user.role.name !== "Super Admin" && (
              <div style={{ marginRight: 15 }}>
                <Button
                  variant="contained"
                  icon={<AddIcon />}
                  title="Bulk upload"
                  onClick={() => navigate("/application/bulk-upload")}
                />
              </div>
            )}
            {user && user.role && user.role.name !== "Super Admin" && (
              <Button
                variant="contained"
                icon={<AddIcon />}
                className=""
                title="ADD PRODUCT"
                onClick={() => navigate("/application/add-products")}
              />
            )}
          </div>
        </div>
        <FilterComponent
          fields={filterFields}
          state={filters}
          stateHandler={setFilters}
          onReset={onReset}
          onFilter={onFilter}
        />
        <InventoryTableSeller
          columns={columns}
          data={products}
          onRefresh={handleRefresh}
          totalRecords={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={(val) => setPage(val)}
          handleRowsPerPageChange={(val) => setRowsPerPage(val)}
          getProducts ={getProducts}
          user={user}
        />
      </div>
    </>
  );
}
